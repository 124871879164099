import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
  constructor() {
    super()
  }

  //用户列表 
  userList(param){
    return this.postJson('/web/userInfo/list', param)
  }
  //用户是否禁用
  updatecSideUser(param){
    return this.postJson('/web/userInfo/updateIsEnable', param)
  }
  //用户收藏列表 
  userCollectionInfo(param){
    return this.postJson('/web/userCollectionInfo/list', param)
  }
  //用户收藏列表删除 
  userCollectionRemove(param){
    return this.postJson('/web/userCollectionInfo/remove', param)
  }

  //删除 
  userRemove(param){
    return this.postJson('/web/userInfo/remove', param)
  }

  //菜品管理列表
  foodList(param){
    return this.postJson('/web/foodInfo/list', param)
  }
  //添加菜品
  saveFood(param){
    return this.postJson('/web/foodInfo/save', param)
  }
  //修改菜品
  updateFood(param){
    return this.postJson('/web/foodInfo/update', param)
  }
  //删除菜品
  removeFood(param){
    return this.postJson('/web/foodInfo/remove', param)
  }

  // 菜品分类列表
  foodClassList(param){
    return this.postJson('/web/foodClassifyInfo/list', param)
  }
  // 菜品分类添加
  saveFoodClass(param){
    return this.postJson('/web/foodClassifyInfo/save', param)
  }
  // 菜品分类修改
  updateFoodClass(param){
    return this.postJson('/web/foodClassifyInfo/update', param)
  }
  // 菜品分类删除
  removeFoodClass(param){
    return this.postJson('/web/foodClassifyInfo/remove', param)
  }

  //食材分类 
  listIngredientsClass(param){
    return this.postJson('/web/ingredientsClassifyInfo/list', param)
  }
  //添加食材分类 
  saveIngredientsClass(param){
    return this.postJson('/web/ingredientsClassifyInfo/save', param)
  }
  //修改食材分类 
  updateIngredientsClass(param){
    return this.postJson('/web/ingredientsClassifyInfo/update', param)
  }
  //删除食材分类 
  removeIngredientsClass(param){
    return this.postJson('/web/ingredientsClassifyInfo/remove', param)
  }

  /**食材管理 */
  //食材列表 
  ingredientsList(param){
    return this.postJson('/web/ingredientsInfo/list', param)
  }
  //添加食材列表 
  ingredientsSave(param){
    return this.postJson('/web/ingredientsInfo/save', param)
  }
  //修改食材列表 
  ingredientsUpdate(param){
    return this.postJson('/web/ingredientsInfo/update', param)
  }
  //删除食材列表 
  ingredientsRemove(param){
    return this.postJson('/web/ingredientsInfo/remove', param)
  }

  /**食材分类管理 */ 
  //列表 
  foodIngredientsList(param){
    return this.postJson('/web/foodIngredientsInfo/list', param)
  }
  //添加列表
  foodIngredientsSave(param){
    return this.postJson('/web/foodIngredientsInfo/save', param)
  }
  //修改列表 
  foodIngredientsUpdate(param){
    return this.postJson('/web/foodIngredientsInfo/update', param)
  }
  //删除列表 
  foodIngredientsRemove(param){
    return this.postJson('/web/foodIngredientsInfo/remove', param)
  }

  /**营养成分管理 */ 
  //列表
  nutritionalList(param){
    return this.postJson('/web/nutritionalComponentsInfo/list', param)
  }
  //添加列表
  nutritionalSave(param){
    return this.postJson('/web/nutritionalComponentsInfo/save', param)
  }
  //修改列表
  nutritionalUpdate(param){
    return this.postJson('/web/nutritionalComponentsInfo/update', param)
  }
  //删除列表
  nutritionalRemove(param){
    return this.postJson('/web/nutritionalComponentsInfo/remove', param)
  }

  /**食材营养成分 */ 
  //列表 
  ingredientsNutritionalList(param){
    return this.postJson('/web/ingredientsNutritionalComponentsInfo/list', param)
  }
  //添加列表 
  ingredientsNutritionalSave(param){
    return this.postJson('/web/ingredientsNutritionalComponentsInfo/save', param)
  }
  //修改列表 
  ingredientsNutritionalUpdate(param){
    return this.postJson('/web/ingredientsNutritionalComponentsInfo/update', param)
  }
  //删除列表 
  ingredientsNutritionalRemove(param){
    return this.postJson('/web/ingredientsNutritionalComponentsInfo/remove', param)
  }

  /**食谱信息 */
  //列表 
  recipesInfoList(param){
    return this.postJson('/web/recipesInfo/list', param)
  }
  //添加
  recipesInfoSave(param){
    return this.postJson('/web/recipesInfo/save', param)
  }
  //修改 
  recipesInfoUpdate(param){
    return this.postJson('/web/recipesInfo/update', param)
  }
  //删除 
  recipesInfoRemove(param){
    return this.postJson('/web/recipesInfo/remove', param)
  }
  // 修改食谱是否在线
  recipesIsOnlineInfo(param){
    return this.postJson('/web/recipesInfo/updateIsOnline', param)
  }
  
  /**食谱明细管理 */
  //列表 
  recipesDetailInfoList(param){
    return this.postJson('/web/recipesDetailInfo/list', param)
  }
  //添加列表 
  recipesDetailInfoSave(param){
    return this.postJson('/web/recipesDetailInfo/save', param)
  }
  //修改列表 
  recipesDetailInfoUpdate(param){
    return this.postJson('/web/recipesDetailInfo/update', param)
  }
  //删除列表 
  recipesDetailInfoRemove(param){
    return this.postJson('/web/recipesDetailInfo/remove', param)
  }

  /**食谱优惠 */
  //列表 
  recipesDiscountInfoList(param){
    return this.postJson('/web/recipesDiscountInfo/list', param)
  }
  //添加列表 
  recipesDiscountInfoSave(param){
    return this.postJson('/web/recipesDiscountInfo/save', param)
  }
  //修改列表 
  recipesDiscountInfoUpdate(param){
    return this.postJson('/web/recipesDiscountInfo/update', param)
  }
  //删除列表 
  recipesDiscountInfoRemove(param){
    return this.postJson('/web/recipesDiscountInfo/remove', param)
  }

  /**食谱明细菜品管理 */
  //列表
  recipesDetailFoodInfoList(param){
    return this.postJson('/web/recipesDetailFoodInfo/list', param)
  }
  //添加列表
  recipesDetailFoodInfoSave(param){
    return this.postJson('/web/recipesDetailFoodInfo/save', param)
  }
  //修改列表
  recipesDetailFoodInfoUpdate(param){
    return this.postJson('/web/recipesDetailFoodInfo/update', param)
  }
  //删除列表
  recipesDetailFoodInfoRemove(param){
    return this.postJson('/web/recipesDetailFoodInfo/remove', param)
  }

  /**配送地点 */
  //列表
  deliveryPointInfoList(param){
    return this.postJson('/web/deliveryPointInfo/list', param)
  }
  //添加列表
  deliveryPointInfoSave(param){
    return this.postJson('/web/deliveryPointInfo/save', param)
  }
  //修改列表
  deliveryPointInfoUpdate(param){
    return this.postJson('/web/deliveryPointInfo/update', param)
  }
  //删除列表
  deliveryPointInfoRemove(param){
    return this.postJson('/web/deliveryPointInfo/remove', param)
  }

  /**获取营养成分列表 */
  ingredientsInfo(param){
    return this.postJson('/web/ingredientsInfo/searchIngredientsInfoByIngredientsId', param)
  }

  //根据食材获取营养列表 
  nutritionalComponents(param){
    return this.postJson('/web/foodInfo/searchNutritionalComponentsInfoListByIngredientsCode', param)
  }

  //根据食材id获取食材信息 
  searchFoodInfoByFoodId(param){
    return this.postJson('/web/foodInfo/searchFoodInfoByFoodId', param)
  }

  //查询食谱每天的营养成分 
  searchNutritionalDay(param){
    return this.postJson('/web/recipesDetailInfo/searchNutritionalComponentsInfoListByCondition', param)
  }

  //获取分类列表   
  searchParentClassifyList(param){
    return this.postJson('/web/foodClassifyInfo/searchParentClassifyList', param)
  }

  //获取分类树列表 
  searchFoodClassifyTreeList(param){
    return this.postJson('/web/foodInfo/searchFoodClassifyTreeList', param)
  }

  //导入食材信息 
  importIngredientsInfo(param){
    return this.postJson('/web/ingredientsInfo/importIngredientsInfo', param)
  }

  //获取消息列表 
  messageList(param){
    return this.postJson('/web/messageInfo/list', param)
  }

  //删除消息
  messageRemove(param){
    return this.postJson('/web/messageInfo/remove', param)
  }

  //获取消息详情 
  messageInfo(param){
    return this.postJson('/web/messageInfo/searchMessageInfoById', param)
  }

  //获取消息弹窗列表
  searchAlertMessageList(param){
    return this.postJson('/web/messageInfo/searchAlertMessageList', param)
  }

  //弹窗点击取消 
  updateMessageInfoById(param){
    return this.postJson('/web/messageInfo/updateMessageInfoById', param)
  }

  //复制食谱 
  copyRecipesInfo(param){
    return this.postJson('/web/recipesInfo/copyRecipesInfo', param)
  }

  //分页查询食谱明细列表 
  recipesDetailPageList(param){
    return this.postJson('/web/recipesDetailInfo/searchRecipesDetailPageList', param)
  }

  //复制食谱明细
  copyRecipesDetailInfo(param){
    return this.postJson('/web/recipesDetailInfo/copyRecipesDetailInfo', param)
  }

  //删除当天食谱明细 
  removeRecipesDetailInfo(param){
    return this.postJson('/web/recipesDetailInfo/removeRecipesDetailInfo', param)
  }

  //查询食谱营养成分 
  byCondition(param){
    return this.postJson('/web/foodInfo/searchNutritionalComponentsInfoListByCondition', param)
  }

  /**推广管理 */
  //推广渠道列表 
  channelsList(param){
    return this.postJson('/web/promotionChannelsInfo/list', param)
  }
  //添加推广渠道
  channelsSave(param){
    return this.postJson('/web/promotionChannelsInfo/save', param)
  }
  //修改推广渠道
  channelsUpdate(param){
    return this.postJson('/web/promotionChannelsInfo/update', param)
  }
  //删除推广渠道
  channelsRemove(param){
    return this.postJson('/web/promotionChannelsInfo/remove', param)
  }

  /**推广人员管理 */
  //推广人员列表 
  promotersList(param){
    return this.postJson('/web/promotersInfo/list', param)
  }
  // 添加推广人员
  promotersSave(param){
    return this.postJson('/web/promotersInfo/save', param)
  }
  //修改推广人员
  promotersUpdate(param){
    return this.postJson('/web/promotersInfo/update', param)
  }
  //删除推广人员
  promotersRemove(param){
    return this.postJson('/web/promotersInfo/remove', param)
  }
  //推广渠道统计 
  PromotionChannelsInfo(param){
    return this.postJson('/web/promotionChannelsInfo/searchPromotionChannelsInfoPageList', param)
  }

  /**
   * 优惠券管理
   */
  //优惠券列表 
  couponList(param){
    return this.postJson('/web/couponInfo/list', param)
  }
  //优惠券保存
  couponSave(param){
    return this.postJson('/web/couponInfo/save', param)
  }
  //优惠券修改
  couponUpdate(param){
    return this.postJson('/web/couponInfo/update', param)
  }
  //优惠券删除
  couponRemove(param){
    return this.postJson('/web/couponInfo/remove', param)
  }
  //发放优惠券
  couponUserSave(param){
    return this.postJson('/web/couponUserInfo/save', param)
  }

  //用户优惠券列表 
  couponUserList(param){
    return this.postJson('/web/couponUserInfo/list', param)
  }
  
  //批量申请取消订餐 
  batchApplyCancelOrderMeal(param){
    return this.postJson('/web/recipesOrderDetailInfo/batchApplyCancelOrderMeal', param)
  }

  // 开启关闭优惠券状态 
  updateIsEnabled(param){
    return this.postJson('/web/couponInfo/updateIsEnabled', param)
  }

  //食谱明细菜品排序 
  recipesSort(param){
    return this.postJson('/web/recipesDetailInfo/sort', param)
  }

  //标签列表 
  labelList(param){
    return this.postJson('/web/recipeTag/list', param)
  }
  //修改标签列表 
  updateLabel(param){
    return this.postJson('/web/recipeTag/saveOrUpdate', param)
  }
  //删除标签列表 
  removeLabel(param){
    return this.postJson('/web/recipeTag/delRecipeTag', param)
  }
  
}

export default new BaseManager()